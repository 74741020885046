import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";

const Refund = () => {
  return (
    <Fragment>
      <Row>
        <Col xs={12} lg={2}></Col>
        <Col xs={12} lg={8}>
          <div
            style={{
              border: "1px solid grey",
              margin: "20px",
              padding: "20px",
            }}
          >
            <h6>
              <u style={{ color: "grey" }}>
                <span style={{ color: "black" }}>
                  Refund and Retrun Policy of TamilVokkaligaMatrimony
                </span>
              </u>
            </h6>
            <div style={{ padding: "10px" }}>
              <span style={{ fontSize: "12px" }}>
              We thank you for choosing Tamil Vokkaliga Matrimony. Before proceeding with any 
              payment, we strongly advise you to thoroughly read and understand our refund 
              policy. This policy outlines the terms and conditions regarding refunds for 
              payments made to us.
                <br />
                <span style={{ color: "grey", padding: "5px" }}>
                <h6> Digital Nature of Services</h6>

 All services provided by Tamil Vokkaliga Matrimony, including membership plans, profile promotions, and personalized matchmaking, are delivered digitally or
 through direct interaction with our team.
 Due to the intangible nature of these services, they are not subject to physical returns.
 Non-Returnable Services

The following services are non-returnable and cannot be exchanged:

    <h6>Membership subscriptions</h6>

     Profile promotions

     Matchmaking and consultation services
     Any other digital or service-based offerings
     Once a service has been initiated or delivered, it is considered consumed and is non-returnable.

     <h6>Service Errors</h6>

 In the unlikely event of an error in service delivery caused by Tamil Vokkaliga Matrimony, we will make every effort to rectify the issue promptly.
 Any requests for rectification must be submitted to our support team within 7 days of the issue being identified.

Requests must include:
Detailed description of the issue
Proof of payment
Relevant transaction details

<h6> Customer Responsibility </h6>

It is the responsibility of the customer to:

Review the details of the services offered before purchase
Ensure all information provided during service enrollment is accurate
Any issues arising from incorrect information 
supplied by the customer are not eligible for returns or rectifications.
                </span>
                <br />
                <br />
                <h6>
                 
                </h6>
                All funds paid to us are considered non-refundable unless explicitly stated otherwise in writing.
               By making a payment, you agree to the terms and conditions set forth in this policy.
                This policy applies to all membership plans, profile promotions, and other services provided by Tamil Vokkaliga Matrimony.
                <br />
                <br />
                <h6>Non-Refundable Payments</h6>

                 Payments made for the following are strictly non-refundable:

                Membership fees
                Profile promotion services
                Personalized matchmaking services
                One-time consultation charges
                <br />
                <br />
                No refunds will be issued for unused services, partial services, or changes of mind.
               All sales are final. Please ensure you thoroughly review the details of the membership plans or services before making a purchase.
               
               
                <h6>How to address your Grievance </h6>To address your grievance
                with TamilVokkaligaMatrimony.com, you can contact the Grievance
                Officer, Mr. Sundara Moorthy at the following details:
                <br />
                <br />
                <b>The Grievance Officer: </b>Mr. Sundara Moorthy
                <br />
                <b>Address: </b>37-G, Abirami Complex, First Floor, Vadakku
                Radha Veedhi, Dindigul-1.
                <br />
                <b>Contact Details: </b>Phone: 9865354499, 7639348617
                <br />
                <b>Email: </b>admin@tamilvokkaligamatrimony.com
                <br />
                <br />
                The Grievance Officer is available between 10 am to 6 pm IST
                from Monday to Saturday, excluding Sundays and Public Holidays
                in India.
                <br />
                <br />
                This appointment is in accordance with Section 5 (9) of the
                Information Technology (Reasonable Security & Procedures and
                Sensitive Personal Data or Information) Rule, 2011.
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={2}></Col>
      </Row>
    </Fragment>
  );
};

export default Refund;
