import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { API_BASE_URL } from "../../api_config";
import UserContext from "../../Authcontext";

function StripeOne({ active, details }) {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const product = {
    name: "Silver",
    price: 1500,
    quantity: 1,
  };

  async function makePayment() {
    try {
      setLoading(true);
      const body = { product };

      const response = await axios.post(
        `${API_BASE_URL}/api/create-checkout-session`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const session = response.data;

      if (session.paymentUrl) {
        window.location.href = session.paymentUrl;
      } else {
        throw new Error("Payment URL not found.");
      }
    } catch (error) {
      alert("Payment error: " + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <Card style={{ width: "25rem", border: active ? "2px solid green" : "1px solid #ddd" }}>
        <Card.Body>
          <Card.Title className="text-center display-4">{product.name} Plan</Card.Title>
          {active && <div className="text-center text-success mb-2">Active Plan</div>}
          <div className="text-center mb-4">
            <span className="h2">{new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(product.price)}</span>
            <span className="h6 text-muted ml-2">/month</span>
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h5 className="mb-2">Plan Details:</h5>
              <ul>
                <li><span className="bullet">&#8226;</span>Get 20 contacts and details</li>
                <li><span className="bullet">&#8226;</span>Enjoy unlimited horoscope checks</li>
                <li className="text-success font-weight-bold">
                  Total: {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(product.price)}
                </li>
              </ul>
            </ListGroup.Item>
          </ListGroup>
          <div className="text-center mt-4">
            <Button
              variant={active ? "secondary" : "primary"}
              size="lg"
              onClick={makePayment}
              disabled={loading || active}
              aria-label={
                active
                  ? `You are already on the ${product.name} Plan`
                  : `Proceed to Payment for ${product.name} Plan`
              }
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Processing...
                </>
              ) : active ? (
                "Already Active"
              ) : (
                "Proceed to Payment"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default StripeOne;