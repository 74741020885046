import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api_config";
import UserContext from "../../Authcontext";
import Footer from "../../Foote/Footer";

function Success() {
  const { token, fetchUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  console.log("recived transaction id" , merchantTransactionId)
  const merchantTransactionId = searchParams.get("merchantTransactionId");
  console.log("after recived transaction id" , merchantTransactionId)

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        if (merchantTransactionId) {
          const response = await axios.put(
            `${API_BASE_URL}/api/payment/success?merchantTransactionId=${merchantTransactionId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              withCredentials: true,
            }
          );

          if (response.data.success) {
            fetchUserData(); // Update user data
            setLoading(false);
          } else {
            throw new Error("Payment verification failed.");
          }
        } else {
          throw new Error("Missing transaction ID.");
        }
      } catch (err) {
        setError(err.message || "An error occurred.");
        setLoading(false);
      }
    };

    verifyPayment();
  }, [merchantTransactionId, token, fetchUserData]);

  const handleViewAllPackages = () => {
    navigate("/");
  };

  if (loading) {
    return <div className="text-center py-5">Verifying your payment...</div>;
  }

  if (error) {
    return (
      <div className="d-flex flex-column min-vh-100">
        <div className="container text-center py-5 flex-grow-1">
          <h2 className="display-4 mb-4 text-danger">Error!</h2>
          <h4>{error}</h4>
          <button
            className="btn btn-primary mt-3 mb-2"
            onClick={handleViewAllPackages}
          >
            Back to Home
          </button>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="container text-center py-5 flex-grow-1">
        <div className="jumbotron">
          <h2 className="display-4 mb-4">Thank You!</h2>
          <h4>Your payment was successful.</h4>
          <p className="lead">
            We appreciate your business! If you have any questions, please email us
            at{" "}
            <a href="mailto:tamilvokkaligamatrimony@gmail.com">
              tamilvokkaligamatrimony@gmail.com
            </a>
            .
          </p>
          <button
            className="btn btn-primary mt-3 mb-2"
            onClick={handleViewAllPackages}
          >
            Back to Home
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Success;
