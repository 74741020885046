import React from "react";
import { FaFacebook, FaWhatsapp, FaInstagram } from "react-icons/fa";
import "./footer.css";
import { Link,  } from "react-router-dom";



const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="foot col-md-4">
            <h3>About Us</h3>
            <p>
              We are a matrimony website dedicated to helping people find their
              life partners. Our mission is to make the process of finding a
              partner easy, safe, and enjoyable.
            </p>
          </div>
          <div className="foot col-md-4">
            <h3>Contact Us</h3>
            <p>
              37-G, Abirami complex, First floor,
              <br />
              vadakku radha veedhi,
              <br/>
              Dindigul-1.
              <br />
              Phone: 9865354499, 7639348617.
            </p>
          </div>
          <div className="foot col-md-4">
            <h3>Our'sPolicy</h3>
            <p>
                          <Link to="/terms-conditions">Terms & Conditions</Link> {" "}
              <br />
                           <Link to="/privacy-policy">Privacy Policy</Link> {" "}
              <br/>
                            <Link to="/refund_policy">Refund & Return Policy</Link>
              
              <br />
            </p>
          </div>
          <div className="foot col-md-4">
            <h3>Follow Us</h3>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/">
                  <FaFacebook />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <FaWhatsapp />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="left text-white col-md-6 text-center">
          <p>© 2023 Matrimony. All Rights Reserved.</p>
        </div>
        <div className="rig text-white col-md-6">
          <p>Designed and Developed By Virtual Technologies </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
