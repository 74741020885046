import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api_config";

import StripeOne from "../StripePay/Stripe1";
import StripeTwo from "../StripePay/Stripe2";
import StripeThree from "../StripePay/Stripe3";

import "./payment.css";

function StripePayment({ userId }) {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/payment-details/${userId}`);
        const responseData = response.data || [];

        if (Array.isArray(responseData) && responseData.length > 0) {
          setPaymentDetails(responseData);
        } else {
          // No subscription plans found
          setShowSubscriptionAlert(true);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching payment details:", err);

        if (err.response?.status === 404) {
          // Show subscription alert instead of immediate redirect
          setShowSubscriptionAlert(true);
        } else {
          setError(err.response?.data?.message || err.message || "Failed to fetch payment details");
        }
        setLoading(false);
      }
    };

    if (userId) {
      fetchPaymentDetails();
    }
  }, [userId, navigate]);

  const handleSubscribeClick = () => {
    navigate("/subscription");
  };

  const alertStyles = {
    maxWidth: "500px",
    margin: "40px auto",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  };

  const buttonStyles = {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "15px",
  };

  const errorStyles = {
    ...alertStyles,
    border: "1px solid #dc3545",
    color: "#dc3545",
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "40px" }}>
        <div>Loading payment details...</div>
      </div>
    );
  }

  if (showSubscriptionAlert) {
    return (
      <div style={alertStyles}>
        <h2 style={{ marginBottom: "15px", color: "#333" }}>No Active Subscription</h2>
        <p style={{ marginBottom: "20px", color: "#666" }}>
          You currently don't have an active subscription plan. A subscription is required to access this feature.
        </p>
        <button onClick={handleSubscribeClick} style={buttonStyles}>
          View Subscription Plans
        </button>
      </div>
    );
  }

  if (error) {
    return (
      <div style={errorStyles}>
        <h2 style={{ marginBottom: "10px" }}>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  const getMembershipDetails = (membership) =>
    paymentDetails.find((details) => details.pay_membership === membership) || null;

  return (
    <>
      <div className="pay">
        <StripeOne active={!!getMembershipDetails("Silver")} details={getMembershipDetails("Silver")} />
        <StripeTwo active={!!getMembershipDetails("Gold")} details={getMembershipDetails("Gold")} />
        <StripeThree active={!!getMembershipDetails("Platinum")} details={getMembershipDetails("Platinum")} />
      </div>
      <div className="payment-footer">
        <div className="wrapper-max">
          <center>
            <span className="pay-footer-card-visa"></span>
            <span className="pay-footer-card-mastercard"></span>
            <span className="pay-footer-card-dss"></span>
            <span className="pay-footer-card-trustwave"></span>
            <span className="pay-footer-card-safekey"></span>
            <span className="pay-footer-card-verisign"></span>
            <span className="pay-footer-card-masterpass"></span>
            <br clear="all" />
          </center>
        </div>
      </div>
    </>
  );
}

export default StripePayment;
