import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./viewpro.css";
import axios from "axios";
import { API_BASE_URL } from "../api_config";

import blur from "../../assets/blur.jpeg";
import UserContext from "../Authcontext";
import { calcLength } from "framer-motion";

function ViewProfile() {
  const { user } = useContext(UserContext);
  const [getProf, setGetProf] = useState({});
  const location = useLocation();
  const profId = location.state.userId;

  useEffect(() => {
    console.log("recived user" , user)
    console.log("profId: " + profId);
    axios
      .get(`${API_BASE_URL}/api/get-prof/${profId}`, { withCredentials: true })
      .then((res) => setGetProf({ ...res.data[0] }))
      .catch((err) => {});
  }, [profId, user]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen) {
        const modalContent = document.querySelector(".preview-modal-content");
        if (!modalContent.contains(event.target)) {
          setIsModalOpen(false);
        }
      }
    };

    window.addEventListener("click", handleOutsideClick, true);

    return () => {
      window.removeEventListener("click", handleOutsideClick, true);
    };
  }, [isModalOpen]);

  function formatDOB(dob) {
    const dobDate = new Date(dob);
    const year = dobDate.getFullYear();
    const month = String(dobDate.getMonth() + 1).padStart(2, "0");
    const day = String(dobDate.getDate()).padStart(2, "0");
    const formattedDOB = `${year}-${month}-${day}`;
    return formattedDOB;
  }

  function numFor(number) {
    const numFormat = Intl.NumberFormat("en-IN");
    const newFor = numFormat.format(number);
    return newFor;
  }

  return (
    <section>
      <div className="view-prof-container">
        <div className="view-prof-left">
          <div className="view-prof-left-top">
            <img
              src={`${API_BASE_URL}/uploads/` + getProf.filename}
              alt={`${getProf.first_name}`}
              className="get-prof-img"
              onClick={() =>
                toggleImageModal(`${API_BASE_URL}/uploads/` + getProf.filename)
              }
            />
            <h1 style={{ textAlign: "center" }}>
              {getProf.first_name} {getProf.last_name}
            </h1>
          </div>
          <div className="view-prof-left-basic-info">
            <p>
              dob: <span>{formatDOB(getProf.dob)}</span>
            </p>
            <p>
              age: <span>{getProf.age}</span>
            </p>
            <p>
              email:
              {user.pay_membership === "Gold" ||
              user.pay_membership === "Silver" ||
              user.pay_membership === "Diamond" ? (
                <span>{getProf.email}</span>
              ) : (
                <span>
                  <img
                    src={blur}
                    alt="upgrade"
                    style={{ width: "175px", height: "40px" }}
                  />
                </span>
              )}
            </p>
            <p>
              mobile no:
              {user.pay_membership === "Gold" ||
              user.pay_membership === "Silver" ||
              user.pay_membership === "Diamond" ? (
                <span>{getProf.mobile_number}</span>
              ) : (
                <span>
                  <img
                    src={blur}
                    alt="upgrade"
                    style={{ width: "150px", height: "40px" }}
                  />
                </span>
              )}
            </p>
            <p className="edu-fit">
              education: <span>{getProf.education}</span>
            </p>
            <p>
              occupation: <span>{getProf.occupation}</span>
            </p>
            <p>
              Company:
              {user.pay_membership === "Gold" ||
              user.pay_membership === "Silver" ||
              user.pay_membership === "Diamond" ? (
                <span>{getProf.company_name}</span>
              ) : (
                <span>
                  <img
                    src={blur}
                    alt="upgrade"
                    style={{ width: "200px", height: "40px" }}
                  />
                </span>
              )}
            </p>
            <p>
              <span style={{ marginLeft: "0px" }}>
                {getProf.city_name}, {getProf.state_name},{" "}
                {getProf.country_name}
              </span>
            </p>
            <p>
              marital status: <span>{getProf.marital_status}</span>
            </p>
            <p>
              mother tongue: <span>{getProf.mother_tongue}</span>
            </p>
            <p>
              employment type: <span>{getProf.employment_type}</span>
            </p>
            <p>
              income: <span>{numFor(getProf.income)} LPA</span>
            </p>
            <p>
              height: <span>{getProf.height} cm</span>
            </p>
            <p>
              weight: <span>{getProf.weight} kg</span>
            </p>
          </div>
        </div>
        <div className="view-prof-right">
          <div className="view-prof-right-basic-info">
            <p>
              father name: <span>{getProf.father_name}</span>
            </p>
            <p>
              mother name: <span>{getProf.mother_name}</span>
            </p>
            <p>
              family status: <span>{getProf.family_status}</span>
            </p>
            <p>
              family type: <span>{getProf.family_type}</span>
            </p>
            <p>
              religion: <span>{getProf.religion}</span>
            </p>
            <p>
              caste: <span>{getProf.caste}</span>
            </p>
            <p>
              kulam: <span>{getProf.kulam}</span>
            </p>
            <p>
              star: <span>{getProf.star}</span>
            </p>
            <p>
              raasi: <span>{getProf.raasi}</span>
            </p>
            <p>
              chevvai dosham:{" "}
              <span>{getProf.chevvai_dosham === "chevvai" ? "Yes" : "No"}</span>
            </p>
            <p>
              naga dosham:{" "}
              <span>{getProf.naga_dosham === "naga" ? "Yes" : "No"}</span>
            </p>
            <p>
              home details: <span>{getProf.home_details}</span>
            </p>
            <p>
              agri land: <span>{getProf.agri_land}</span>
            </p>
            <p>
              food habits: <span>{getProf.food_habits}</span>
            </p>
          </div>
        </div>
      </div>
      {user.pay_membership === "Gold" ||
      user.pay_membership === "Silver" ||
      user.pay_membership === "Diamond" ? (
        <div className="horoscope-main-container">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <div className="horo-img">
              <div className="horoscope-container">
                <div className="horoscope-box">
                  <input type="text" name="1" value={getProf.box1} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="2" value={getProf.box2} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="3" value={getProf.box3} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="4" value={getProf.box4} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="5" value={getProf.box5} disabled />
                </div>
                <div className="horoscope-box"></div>

                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                  <input type="text" name="6" value={getProf.box6} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="7" value={getProf.box7} disabled />
                </div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                  <input type="text" name="8" value={getProf.box8} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="9" value={getProf.box9} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="10" value={getProf.box10} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="11" value={getProf.box11} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="12" value={getProf.box12} disabled />
                </div>
              </div>
            </div>
            <div className="horo-img">
              <div className="horoscope-container">
                <div className="horoscope-box">
                  <input type="text" name="13" value={getProf.box13} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="14" value={getProf.box14} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="15" value={getProf.box15} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="16" value={getProf.box16} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="17" value={getProf.box17} disabled />
                </div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                  <input type="text" name="18" value={getProf.box18} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="19" value={getProf.box19} disabled />
                </div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                  <input type="text" name="20" value={getProf.box20} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="21" value={getProf.box21} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="22" value={getProf.box22} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="23" value={getProf.box23} disabled />
                </div>
                <div className="horoscope-box">
                  <input type="text" name="24" value={getProf.box24} disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Link
            to="/payment/paymentoption"
            className="vp-upgrade-link btn btn-primary"
          >
            Upgrade to view horoscope
          </Link>
        </div>
      )}
      {isModalOpen && (
        <div className="preview-image-modal">
          <div className="preview-modal-content">
            <span
              className="preview-close"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Full Size"
              className="preview-image"
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default ViewProfile;
