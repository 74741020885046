import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CgProfile } from "react-icons/cg";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../assets/logo.png";
import "./header.css";

import { API_BASE_URL } from "../api_config";

const Header = ({ file, name }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("m_host-token");
    navigate("/signup", { replace: true });
  };

  return (
    <nav className="custom-navbar">
      <div className="custom-wrapper">
        <NavLink to="/">
          <img src={logo} alt="logo" className="custom-logo" />
        </NavLink>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="custom-nav-links">
          <label htmlFor="close-btn" className="custom-btn custom-close-btn">
            <FaTimes />
          </label>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/searchbox">Search</NavLink>
          </li>
          <li>
            <NavLink className="custom-desktop-item">Matches</NavLink>
            <input type="checkbox" id="showDropMatches" />
            <label htmlFor="showDropMatches" className="custom-mobile-item">
              Matches
            </label>
            <ul className="custom-drop-menu">
              <li>
                <NavLink to="/matches/daily-recommendations">
                  Daily Recommendations
                </NavLink>
              </li>
              <li>
                <NavLink to="/matches/total-matches">Total Matches</NavLink>
              </li>
              <li>
                <NavLink to="/matches/partner-preferences">
                  Partner Preference
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            {" "}
            <NavLink to="/mailbox">Mailbox</NavLink>
          </li>
          <li>
            {" "}
            <NavLink to="/payment/paymentoption">Upgrade Now</NavLink>
          </li>
          <li>
            <NavLink className="custom-desktop-item" id="bg-prof-hov">
              {file === null || file === undefined ? (
                <CgProfile size={30} />
              ) : (
                <img
                  src={`${API_BASE_URL}/uploads/` + file}
                  alt={`${name}`}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    objectPosition: "top",
                    borderRadius: "50%",
                  }}
                />
              )}
            </NavLink>
            <input type="checkbox" id="showDropProf" />
            <label htmlFor="showDropProf" className="custom-mobile-item">
              {file === null || file === undefined ? (
                <CgProfile size={30} />
              ) : (
                <img
                  src={`${API_BASE_URL}/uploads/` + file}
                  alt={`${name}`}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    objectPosition: "top",
                    borderRadius: "50%",
                  }}
                />
              )}
            </label>
            <ul className="custom-drop-menu-prof">
              <li>
                <NavLink to="/edit-profile">Edit Profile</NavLink>
              </li>
              <li>
                <NavLink to="/edit-partner-preferences">
                  Edit Preferences
                </NavLink>
              </li>
              <li>
                <NavLink to="/horoscope">Horoscope</NavLink>
              </li>
              <li>
                <NavLink to="/Mailbox">Mailbox</NavLink>
              </li>
              <li>
                <button
                  type="button"
                  className="logout-btn"
                  onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="custom-btn custom-menu-btn">
          <FaBars />
        </label>
      </div>
    </nav>
  );
};

export default Header;
