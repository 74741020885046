import React, { useState, createContext, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { API_BASE_URL } from "./api_config";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [likedProfiles, setLikedProfiles] = useState([]);
  const [token, setToken] = useState(Cookies.get("m_host-token") || null);

  const fetchUserData = useCallback(async () => {
    if (token) {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setUser((prevUser) => ({
          ...prevUser,
          ...response.data[0],
        }));
      } catch (error) {
        alert("Something error occured");
      }
    }
  }, [token]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user.my_interest && token) {
      const myInterestArray = user.my_interest
        ? user.my_interest.split(",").map((interest) => parseInt(interest))
        : [];
      const filteredLikedProfiles = myInterestArray.filter(
        (interest) => !isNaN(interest)
      );
      setLikedProfiles(filteredLikedProfiles);
    }
  }, [user.my_interest, token]);

  useEffect(() => {
    if (token) {
      Cookies.set("m_host-token", token, { expires: 1 / 24 });
    } else {
      Cookies.remove("m_host-token");
    }
  }, [token]);

  const isAuthenticated = !!token;

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthenticated,
        likedProfiles,
        setLikedProfiles,
        fetchUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;





// import React, { useState, createContext, useEffect, useCallback } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";

// import { API_BASE_URL } from "./api_config";

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState({});
//   const [likedProfiles, setLikedProfiles] = useState([]);
//   const [token, setToken] = useState(Cookies.get("m_host-token") || null);

//   const fetchUserData = useCallback(async () => {
//     if (token) {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/api/user`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//           withCredentials: true,
//         });

//         // Check if the API response contains the user data directly or inside an array
//         const userData = Array.isArray(response.data)
//           ? response.data[0] // If array, take the first item
//           : response.data;   // Otherwise, use the object directly

//         setUser((prevUser) => ({
//           ...prevUser,
//           ...userData, // Merge the fetched user data
//         }));
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//         alert("An error occurred while fetching user details.");
//       }
//     }
//   }, [token]);

//   useEffect(() => {
//     fetchUserData();
//   }, [fetchUserData]);

//   useEffect(() => {
//     if (user.my_interest) {
//       const myInterestArray = user.my_interest
//         .split(",")
//         .map((interest) => parseInt(interest, 10));
//       const filteredLikedProfiles = myInterestArray.filter(
//         (interest) => !isNaN(interest)
//       );
//       setLikedProfiles(filteredLikedProfiles);
//     }
//   }, [user.my_interest]);

//   useEffect(() => {
//     if (token) {
//       Cookies.set("m_host-token", token, { expires: 1 / 24 });
//     } else {
//       Cookies.remove("m_host-token");
//     }
//   }, [token]);

//   const isAuthenticated = !!token;

//   return (
//     <UserContext.Provider
//       value={{
//         user,
//         setUser,
//         token,
//         setToken,
//         isAuthenticated,
//         likedProfiles,
//         setLikedProfiles,
//         fetchUserData,
//       }}
//     >
//       {children}
//     </UserContext.Provider>
//   );
// };

// export default UserContext;
